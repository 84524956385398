import React from "react";
import { Box, Button, Card, Grid, Layer, Table, TableBody, TableCell, TableHeader, TableRow, Text, TextArea } from "grommet";
import { getAppUsersApporteurs, createApporteurUsers, getSorted } from "../service";
import Refresh from "../../elements/refresh";
import SearchComponent from "../../elements/search";
import DateSort from "../../elements/date_sort";
import { Add, StatusGoodSmall } from "grommet-icons";
import { getSimpleDateFR } from "../../../services/utils";
import { getUser } from "../../../services/storage_service";


class AppUserValidated extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appUsers: [],
            popupOpen: false,
            selectedUser: {},
            showCreateNew: false,
            filter: {
                _query: undefined,
                status: undefined,
                sort: undefined
            },
            currentUser: getUser()
        };
        console.log('props = ', props);
    }
    componentDidMount = () => {
        this.loadAppUsers();
    };

    loadAppUsers = () => {
        console.log("props = ", this.props);
        this.setState({ appUsers: [] }, () => {
            getAppUsersApporteurs()
                .then((res) => {
                    this.setState({ appUsers: res.data });
                })
                .catch((err) => {
                    console.log("err", err);
                    this.props.showMessage("Erreur inattendu");
                });
        })
    };

    onClose = () => {
        this.setState({ popupOpen: false, showCreateNew: false });
        this.loadAppUsers();
    };

    openUser = (user) => {
        this.setState({ selectedUser: user, popupOpen: true });
    };

    addUser = () => this.openUser({});

    search = (query) => {

        getAppUsersApporteurs(query)
            .then(res => {
                if (res.data?.results) {
                    this.setState({ appUsers: res.data?.results })
                } else if (res.data) {
                    this.setState({ appUsers: res.data })
                }
            })
            .catch(err => {
                console.log('err', err);
                this.props.showMessage('Erreur inattendu');
            })
    }

    updateFilter = (field, value) => {
        let filter = this.state.filter;
        filter[field] = value;
        this.setState({ filter: Object.assign({}, filter) });
    }

    render() {
        console.log(this.state.appUsers);
        return (
            <Box style={{ width: "100%" }} margin={{ top: "large" }}
                align="center" justify="center"
                alignSelf="center"
                alignContent="center" gap="medium">
                <Text size="xlarge" style={{ padding: 10 }}><b>{this.props.domain ? this.props.domain : 'Utilisateurs Validés'}</b></Text>
                <Box gap="medium">
                    <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium">
                        <Refresh refresh={this.loadAppUsers} />
                        <SearchComponent
                            search={value => {
                                this.search(value, this.state.filter._query);
                            }}
                            query={this.state.filter._query}
                            updateQuery={value => this.updateFilter('_query', value)}
                        />
                        <DateSort
                            onChange={value => this.updateFilter('sort', value)}
                        />
                        <Box direction='row' gap='small' alignSelf="center" align="end" justify="end"
                            alignContent="end">
                            <Button primary icon={<Add />} onClick={() => this.setState({ showCreateNew: true })} label={"Créer utilisateur"} />
                        </Box>
                    </Box>


                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableCell>
                                    <Text>Création/<br />Modification</Text>
                                </TableCell>
                                <TableCell>
                                    <Text>Type</Text>
                                </TableCell>
                                <TableCell>
                                    <Text>Nom</Text>
                                </TableCell>
                                <TableCell>
                                    <Text>Prénom</Text>
                                </TableCell>
                                <TableCell>
                                    <Text>Date de naissance</Text>
                                </TableCell>
                                <TableCell><Text>Email</Text></TableCell>
                                <TableCell><Text>Immobilier</Text></TableCell>
                                <TableCell><Text>Équipe de</Text></TableCell>
                                <TableCell><Text>Accepté / Connecté</Text></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {getSorted(this.state.appUsers, this.state.filter.sort).map((appUser, index) => (
                                <TableRow key={index + 1}>
                                    <TableCell>
                                        <Text size="small"><strong>{getSimpleDateFR(appUser.createdAt)}</strong><br />{getSimpleDateFR(appUser.updatedAt)}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text>{appUser.apporteur_pro?.user_id === appUser.id ? "Administrateur" : "Apporteur"}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text>{appUser.nom}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text>{appUser.prenom}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text>{getSimpleDateFR(appUser.birthday)}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text>{appUser.email}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text>{appUser.domain_activity}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text>{appUser.affiliated_by ? `${appUser.affiliated_by.prenom} ${appUser.affiliated_by.nom}` : (appUser.apporteur_pro?.raisonSocial || '')}</Text>
                                    </TableCell>
                                    <TableCell textAlign={"center"}>
                                        <Text textAlign="center">{appUser.need_change ? "❌" : "✅"}</Text>
                                    </TableCell>
                                    <TableCell>
                                        {appUser.apporteur_pro?.user_id !== appUser.id && <Button
                                            label="Renvoyer mail inscription"
                                            primary
                                            onClick={() => {
                                                createApporteurUsers({
                                                    emails: [appUser.email],
                                                    apporteur_pro: this.state.currentUser.apporteur_pro.id
                                                }).then(() => {
                                                    this.props.showMessage("L'email a été envoyé", "success");
                                                }).catch((err) => {
                                                    this.props.showMessage("Échec d'envoyer email", "error");
                                                });
                                            }}
                                        />}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>

                {this.state.showCreateNew && <Layer style={{ width: 600 }} onClickOutside={this.onClose} onEsc={this.onClose}>
                    <UserAccountCreation showMessage={this.props.showMessage} apporteur={this.state.currentUser.apporteur_pro} />
                </Layer>}
            </Box>
        );
    }
}

const UserAccountCreation = ({ apporteur, showMessage }) => {
    const [emails, setEmails] = React.useState("");
    const [emailsValid, setEmailsValid] = React.useState([]);
    const [emailsNonValid, setEmailsNonValid] = React.useState([]);
    const [showConfirm, setShowConfirm] = React.useState(false);


    const checkAndSendEmails = () => {
        let arrEmails = emails.split("\n").map(e => e.trim());
        let arrEmailsValid = [];
        let arrEmailsNonValid = [];

        for (let i = 0; i < arrEmails.length; i++) {
            const em = arrEmails[i];
            if (em) {
                if (validateEmail(em)) {
                    arrEmailsValid.push(em);
                } else {
                    arrEmailsNonValid.push(em);
                }
            }
        }
        setEmailsNonValid(arrEmailsNonValid);
        setEmailsValid(arrEmailsValid);
        setShowConfirm(true);
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const sendEmailsConnexion = () => {
        if (emailsValid.length > 0) {
            createApporteurUsers({
                emails: emailsValid,
                apporteur_pro: apporteur.id
            }).then(() => {
                showMessage("Les emails ont été envoyé", "success");
                setShowConfirm(false);
                setEmails("");
            }).catch((err) => {
                showMessage("Échec d'envoyer email", "error");
                setShowConfirm(false);
            });
        }
    }

    return (
        <Box pad={'small'} gap="small" width={"large"} >

            <Box gap="xsmall" justify="start" align="start" >
                <Text size="xsmall" >{'Indiquer les mails pour créer les comptes utilisateur de l’apporteur '}</Text>
                <TextArea placeholder="Renseignez les adresses e-mail des apporteurs une par une, à la ligne. 
Exemple :
kevin@trustiway.com
landry@trustiway.com 
etc…
"
                    style={{ minHeight: 120, background: '#EDEDED', borderRadius: 10, border: 'none', }} size="xsmall" value={emails} onChange={event => setEmails(event.target.value)} />
            </Box>
            <Button primary disabled={emails === ""} background={"green"} label={'Valider la saisie et envoyer le mail de connexion'} onClick={() => {
                checkAndSendEmails();
            }} />


            {showConfirm && <Layer style={{ width: 600 }} onClickOutside={() => setShowConfirm(false)} onEsc={() => setShowConfirm(false)}>

                <Grid columns={["50%", "50%"]} pad={"medium"} gap="small" fill>

                    <Box fill>
                        <Text><b>Les emails replis valides</b></Text>
                        <Box style={{ height: 200, overflowY: 'scroll' }} border={{ color: "green" }} pad={'small'}>
                            {emailsValid.map(v => <Text>{v}</Text>)}
                        </Box>
                    </Box>
                    <Box fill>
                        {emailsNonValid.length > 0 && <Box>
                            <Text><b>Les emails replis non valides</b></Text>
                            <Box style={{ height: 200, overflowY: 'scroll' }} border={{ color: "red" }} pad={'small'}>
                                {emailsNonValid.map(v => <Text color={'red'}>{v}</Text>)}
                            </Box>
                        </Box>}

                    </Box>

                </Grid>
                <Box direction="row" gap="small" pad="small" align="center" justify="center">
                    <Button
                        primary background={"lightgray"} label={'Annuler'} onClick={() => {
                            setShowConfirm(false);
                        }} />
                    <Button
                        primary
                        disabled={emailsValid.length <= 0 && apporteur.id}
                        background={"green"} label={'Envoyer le mail de connexion'} onClick={() => {
                            sendEmailsConnexion();
                        }} />
                </Box>

            </Layer>}


        </Box>
    )
}


export default AppUserValidated;
