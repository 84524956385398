import { Grommet, Select } from 'grommet';
import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import UISelect from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

const customTheme = {
    div: {
        width: '100%'
    },
    global: {
        width: '100%',
        control: {
            border: {
                radius: '10px',
            },
            width: '100%'
        },
    },
    select: {
        width: '100%',
        control: {
            extend: () => `
                width: 100%
            `,
            width: '100%'
        },
        container: {
            extend: () => `
                width: 100%
            `,
            width: '100%'
        },
        clear: {
            container: {
                extend: () => `
                    width: 100%
                `,
            }
        }
    }
};

const CustomSelect = ({ style, placeholder, options, value, onChange, multiple, onSearch, valueKey, labelKey }) => {
    return (
        <Grommet theme={customTheme}>
            <Select style={{ width: '100%' }}
                stretch='align'
                size='xsmall'
                className={'custom-select-fill'}
                style={style}
                placeholder={placeholder}
                options={options}
                value={value}
                valueKey={valueKey}
                labelKey={labelKey}
                onChange={onChange}
                multiple={multiple}
                onSearch={onSearch}
            />
        </Grommet>
    )
}

export default CustomSelect;

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(value, options, theme) {
    return {
        fontWeight:
            options.indexOf(value) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export const CustomUISelect = ({ values, options, onChange, ...props }) => {

    const classes = useStyles();
    const theme = useTheme();

    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-chip-label">Chip</InputLabel>
                <UISelect
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={values}
                    onChange={onChange}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                            ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                >
                    {options.map((value) => (
                        <MenuItem key={value} value={value} style={getStyles(value, values, theme)}>
                            {value}
                        </MenuItem>
                    ))}
                </UISelect>
            </FormControl>

        </div>
    );

}