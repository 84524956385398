import React from "react";
import { Box, Image, Menu, ResponsiveContext, Tab, Tabs, Text } from "grommet";

import mainLogo from "../images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../components/elements/layout";
import storageService, { saveDomainAgence } from "../services/storage_service";
import { navigate } from "gatsby-link";
import { logout, getSouscriptionConfig } from "../services/login_service";
import { UserManager } from "grommet-icons";
import AppUserValidated from "../components/my_trustiway/users/validated";
import RelationList from "../components/my_trustiway/relation";
import AppComptable from "../components/my_trustiway/comptable";
import AppComptablePayout from "../components/my_trustiway/comptable/payout";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      menuOpen: false,
      updateNum: 1,
      user: {},
      partenaireData: {},
    };
  }
  componentDidMount() {
    // navigate('/login'); return;
    const token = storageService.getToken();
    if (!token) {
      navigate("/login");
      return;
    }
    this.setState({ token });
    const user = storageService.getUser();
    this.setState({ user });
    // console.log("user = ", user);
    if (user.id) {
      this.getConfigs(user.id);
    }
    if(!user.prenom){
      navigate("/profile");
        return;
    }
  }

  getConfigs = async (uid) => {
    const partenaireDataData = await getSouscriptionConfig(uid);
    const partenaireDataArr = partenaireDataData.data;
    if (partenaireDataArr.length > 0) {
      const partenaireData = partenaireDataArr[0];
      console.log("partenaire = ", partenaireData);
      if (partenaireData.need_change) {
        navigate("/first_time");
        return;
      }
      saveDomainAgence(partenaireData?.domain_email || partenaireData?.domainName);
      this.setState({
        partenaireData,
        updateNum: this.state.updateNum + 1,
      });
    }
  };

  showMessage = (message, messageType) => {
    messageType = messageType || "info";
    toast[messageType](message);
  };

  showMenu = () => {
    this.setState({ menuOpen: true });
  };

  logout = () => {
    logout();
    navigate("/login");
  };

  changepw = () => {
    navigate("/change");
  };

  render() {
    console.log("this.state.partenaireData = ", this.state.partenaireData);
    var _domain = this.state.partenaireData?.domain_email || this.state.partenaireData?.domainName || this?.state?.user?.email?.split('@').pop();
    if (_domain?.length <= 0) {
      _domain = this?.state?.user?.email?.split('@').pop();
    }
    return (
      <Layout style={{ justifyContent: "center", alignContent: "center" }}>
        <ResponsiveContext.Consumer>
          {(size) => (
            <Box
              as="main"
              flex
              overflow="auto"
              style={{ width: "100%", backgroundColor: "#fff" }}
              direction="column"
              alignContent="center"
              alignSelf="center"
            >
              <Box
                justify="center"
                style={{ position: "absolute", left: 20, top: 13 }}
              >
                <Image src={mainLogo} width={200} />
              </Box>
              <Box
                direction="row"
                alignContent="between"
                pad="medium"
                style={{
                  height: 80,
                  width: "100%",
                  backgroundColor: "#F0F0F0",
                }}
              >
                <Box justify="center" flex>
                  <Tabs margin="-6px 0 0 0">

                    <Tab title="Mise en relation">
                      <RelationList key={this.state.updateNum} showMessage={this.showMessage} domain={_domain} apporteurRef={this.state.user?.apporteur_pro?.reference} isAdmin={this.state.user?.apporteur_pro?.user_id === this.state.user.id} />
                    </Tab>
                    {(this.state.user?.apporteur_pro?.mode_remuneration !== 'Full réseaux' || this.state.user?.apporteur_pro?.user_id === this.state.user.id) && <Tab title="Comptabilité">
                      <Box justify="center" pad={"small"} flex>
                        <Tabs>
                          <Tab title="Commission">
                            <AppComptable key={this.state.updateNum} showMessage={this.showMessage} domain={_domain} isAdmin={this.state.user?.apporteur_pro?.user_id === this.state.user.id} hideCommission={this.state.user?.apporteur_pro?.mode_remuneration === 'Full réseaux'} isMother={this.state.user?.apporteur_pro?.company_mother} />
                          </Tab>
                          {this.state.user?.apporteur_pro?.mode_remuneration !== 'Full réseaux' && this.state.user?.apporteur_pro?.user_id !== this.state.user.id && <Tab title="Retrait">
                            <AppComptablePayout key={this.state.updateNum} showMessage={this.showMessage} domain={_domain} />
                          </Tab>}
                        </Tabs>
                      </Box>
                    </Tab>}
                    {this.state.user?.apporteur_pro?.user_id === this.state.user?.id && !this.state.user?.apporteur_pro?.company_mother &&
                      <Tab title="Utilisateurs">
                        <AppUserValidated key={this.state.updateNum} showMessage={this.showMessage} domain={_domain} />
                      </Tab>}
                    {/* <Tab title="Lead Mandat Immobilier">
                      <LeadImmobilierList key={this.state.updateNum} showMessage={this.showMessage} domain={_domain} />
                    </Tab> */}
                  </Tabs>
                </Box>
              </Box>

              <Box
                direction="row"
                gap="small"
                justify="center"
                align="center"
                alignContent="center"
                style={{ position: "absolute", right: 5, top: 13 }}
              >
                <Box>
                  <Text size="small" textAlign="end">
                    Bonjour {this?.state?.user?.apporteur_pro?.user_id ===  this?.state?.user?.id ? <b>{this?.state?.user?.apporteur_pro?.raisonSocial}</b> : <b>{this?.state?.user?.prenom} {this?.state?.user?.nom}</b>}
                  </Text>
                  {this?.state?.user?.apporteur_pro?.company_mother && <Text size="xsmall" textAlign="end"><b>Maison mère</b></Text>}
                  <Text size="xsmall" textAlign="end">
                    {this?.state?.user?.apporteur_pro?.mode_remuneration || ''}
                  </Text>

                </Box>
                <Menu
                  icon={this.state.partenaireData.logo ? <Image src={this.state.partenaireData.logo} style={{ height: 40 }}></Image> : <UserManager />}
                  items={[
                    {
                      label: "Changer votre mot de passe",
                      onClick: this.changepw,
                    },
                    { label: "Se déconnecter", onClick: this.logout },
                  ]}
                />
              </Box>
            </Box>
          )}
        </ResponsiveContext.Consumer>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Layout>
    );
  }
}

export default Home;
