import React from "react";
import { Box, Button, DateInput, Grid, Layer, Table, TableBody, TableCell, TableHeader, TableRow, Text, TextInput } from "grommet";
import { getRemunerationApporteurs, putRequestPayOut, getSorted, getRemunerationMaxTotal, sendRequestRemunerations } from "../service";
import { getSimpleDateFR } from "../../../services/utils";
import Refresh from "../../elements/refresh";
import SearchComponent from "../../elements/search";
import DateSort from "../../elements/date_sort";
import moment from 'moment';
import { Add } from "grommet-icons";
class AppComptablePayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appComptables: [],
      popupOpen: false,
      selectedPartenaire: {},
      startDate: moment(new Date()).startOf('month').toDate(),
      endDate: moment(new Date()).endOf('month').toDate(),
      query: null,
      showRequestPayout: false,
      maxTotal: 0,
      filter: {
        _query: undefined,
        status: undefined,
        sort: undefined
      }
    };
  }
  componentDidMount = () => {
    this.loadAppComptablePayouts();
  };

  loadAppComptablePayouts = (_startDate, _endDate, _query = null) => {
    if (!_startDate || !_endDate) {
      _startDate = this.state.startDate;
      _endDate = this.state.endDate;
    }
    this.setState({ appComptables: [] }, () => {
      getRemunerationApporteurs("PayOut", _startDate, _endDate, _query)
        .then((res) => {
          this.setState({ appComptables: res.data });
          // Get max total
          getRemunerationMaxTotal().then((_res) => {
            this.setState({ maxTotal: _res.data.max_total });
          }).catch((err) => {
            console.log("err", err);
          });
        })
        .catch((err) => {
          console.log("err", err);
          this.props.showMessage("Erreur inattendu");
        });
    })
  };

  onClose = () => {
    this.setState({ popupOpen: false, showRequestPayout: false });
  };

  openPartenaire = (partenaire) => {
    this.setState({ selectedPartenaire: partenaire, popupOpen: true });
  };

  addPartenaire = () => this.openPartenaire({});

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    this.setState({ filter: Object.assign({}, filter) });
  }

  updateStatusPayout(id) {
    putRequestPayOut(id, { status: 'Confirmé' }).then((res) => {
      this.loadAppComptablePayouts();
    })
      .catch((err) => {
        console.log("err", err);
        this.props.showMessage("Erreur inattendu");
      });
  }

  render() {

    console.log(this.state);

    return (
      <Box style={{ width: "100%" }} margin={{ top: "medium" }}
        align="center" justify="center"
        alignSelf="center"
        alignContent="center" gap="medium">
        <Text size="xlarge" style={{ padding: 10 }}><b>Retrait</b></Text>
        <Box width="xlarge" gap="medium">
          <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium">
            <Refresh refresh={this.loadAppComptablePayouts} />
            <Box direction='row' gap='small'
              alignSelf="center" >
              <Box pad="xsmall" justify="center" alignContent="center"
                gap="xsmall"
                align="center" direction="row">
                <Text size="xsmall">De</Text>
                <TextInput
                  size={"small"}
                  placeholder="Date de début"
                  disabled={true}
                  value={
                    this.state.startDate
                      ? new Date(this.state.startDate).toLocaleDateString("fr-FR")
                      : ""
                  }
                  style={{
                    opacity: 1,
                    background: "#EDEDED",
                    fontSize: 12,
                    borderRadius: 10,
                    border: "none",
                  }}
                />
                <DateInput
                  value={this.state.startDate.toISOString()}
                  calendarProps={{
                    locale: "fr-FR",
                    size: "small",
                    // bounds: this.getBounds(),
                  }}
                  onChange={(event) => {
                    this.setState({
                      startDate: new Date(event.value)
                    }, () => {
                      this.loadAppComptablePayouts();
                    });

                  }}
                />
                <Text size="xsmall">À</Text>
                <TextInput
                  size={"small"}
                  placeholder="Date de fin"
                  disabled={true}
                  value={
                    this.state.endDate
                      ? new Date(this.state.endDate).toLocaleDateString("fr-FR")
                      : ""
                  }
                  style={{
                    opacity: 1,
                    background: "#EDEDED",
                    fontSize: 12,
                    borderRadius: 10,
                    border: "none",
                  }}
                />
                <DateInput
                  value={this.state.endDate.toISOString()}
                  calendarProps={{
                    locale: "fr-FR",
                    size: "small",
                    // bounds: this.getBounds(),
                  }}
                  onChange={(event) => {
                    this.setState({
                      endDate: new Date(event.value)
                    }, () => {
                      this.loadAppComptablePayouts();
                    });

                  }}
                />
              </Box>
            </Box>
            <SearchComponent
              search={value => {
                this.setState({
                  query: value
                });
                this.loadAppComptablePayouts(this.state.startDate, this.state.endDate, value);
              }}
              query={this.state.query}
              updateQuery={value => this.setState({ query: value })}
            />
            <DateSort
              onChange={value => this.updateFilter('sort', value)}
            />
            <Box direction='row' gap='small' alignSelf="center" align="end" justify="end"
              alignContent="end">
              <Button primary icon={<Add />} onClick={() => this.setState({ showRequestPayout: true })} label={"Percevoir ma rémunération"} />
            </Box>
          </Box>


          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>
                  <Text>Création/<br />Modification</Text>
                </TableCell>
                <TableCell><Text>Apporteur</Text></TableCell>
                <TableCell>
                  <Text>RIB</Text>
                </TableCell>
                <TableCell>
                  <Text>BIC</Text>
                </TableCell>
                <TableCell>
                  <Text>Status</Text>
                </TableCell>
                <TableCell>
                  <Text color={'red'} weight='bold'>Montant</Text>
                </TableCell>
                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHeader>
            <TableBody>
              {getSorted(this.state.appComptables, this.state.filter.sort).map((appComptable, index) => (
                <TableRow key={index + 1}>
                  <TableCell>
                    <Text size="small"><strong>{getSimpleDateFR(appComptable.createdAt)}</strong><br />{getSimpleDateFR(appComptable.updatedAt)}</Text>
                  </TableCell>


                  <TableCell>
                    <Text>{appComptable.user?.prenom} {appComptable.user?.nom}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{(appComptable.user?.iban || '').substring(0, 4)}******{(appComptable.user?.iban || '').substring(appComptable.user?.iban?.length - 4, appComptable.user?.iban?.length)}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appComptable.user?.bic}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{appComptable.status}</Text>
                  </TableCell>
                  <TableCell>
                    <Text color={'red'} weight='bold'> - {appComptable.amount}€</Text>
                  </TableCell>
                  {/* <TableCell>
                    {appComptable.status !== 'Confirmé' && <Button
                      label="Virement fait"
                      primary
                      onClick={() => {
                        if (window.confirm('Vous êtes sûr que le virement fait ?')) {
                          this.updateStatusPayout(appComptable.id);
                        }
                      }}
                    />}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>

        {this.state.showRequestPayout && <Layer style={{ width: 600 }} onClickOutside={this.onClose} onEsc={this.onClose}>
          <RequestPayoutForm key={`form_${this.state.maxTotal}`} onClose={this.onClose} showMessage={this.props.showMessage} maxTotal={this.state.maxTotal} reload={this.loadAppComptablePayouts} />
        </Layer>}

      </Box>
    );
  }
}

const RequestPayoutForm = ({ maxTotal, showMessage, reload, onClose }) => {
  const [amount, setAmount] = React.useState(maxTotal || 0);


  const checkAndSend = () => {
    if (validateAmount(amount)) {
      sendRequestRemunerations(amount).then((res) => {
        showMessage("Demande envoyé");
        onClose();
        reload();
        
      }).catch((err) => {
        console.log("err", err);
        showMessage("Erreur inattendu");
      });
    }
  }

  const validateAmount = (_amount) => {
    return _amount > 0 && _amount <= maxTotal;
  };



  return (
    <Box pad={'small'} gap="small" width={"large"} >
      <Box gap="xsmall" justify="start" align="start" >
      <Text size="medium" >{'Montant dispo: '}<b>{maxTotal}€</b></Text>
        <Text size="small" >{'Quel est le montant que vous souhaitez percevoir ?'}</Text>
        <TextInput type="number" value={amount} onChange={event => setAmount(event.target.value)} />
      </Box>
      <Button primary background={"green"} label={'Valider'} onClick={() => {
        checkAndSend();
      }} />
    </Box>
  )
}


export default AppComptablePayout;
