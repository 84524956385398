import React from "react";
import { Box, Card, DateInput, Table, TableBody, TableCell, TableHeader, TableRow, Text, TextInput } from "grommet";
import { getRemunerationApporteurs, getSorted, getClientName } from "../service";
import { getSimpleDateFR } from "../../../services/utils";
import Refresh from "../../elements/refresh";
import SearchComponent from "../../elements/search";
import DateSort from "../../elements/date_sort";
import moment from 'moment';

class AppComptable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appComptables: [],
      popupOpen: false,
      selectedPartenaire: {},
      totalCommission: 0,
      totalCommissionMother: 0,
      totalCommissionNetwork: 0,
      totalCommissionPaid: 0,
      totalCommissionPayoutRequested: 0,
      numContrats: 0,
      startDate: moment(new Date()).startOf('month').toDate(),
      endDate: new Date(),
      query: null,
      filter: {
        _query: undefined,
        status: undefined,
        sort: undefined,
      }
    };
  }

  componentDidMount = () => {
    this.loadAppComptables();
  };

  loadAppComptables = (_startDate, _endDate, _query = null) => {
    if (!_startDate || !_endDate) {
      _startDate = this.state.startDate;
      _endDate = this.state.endDate;
    }
    this.setState({ appComptables: [] }, () => {
      getRemunerationApporteurs("PayIn", _startDate, _endDate, _query)
        .then((res) => {
          this.setState({
            appComptables: res.data.filter(e => e.type !== 'PayOut'),
            totalCommissionMother: res.data.reduce((sum, value) => {
              return value.level === 0 ? (parseFloat(`${value.product?.com_network_mother}`) || 0) + sum : sum;
            }, 0) || 0,
            totalCommissionNetwork: res.data.reduce((sum, value) => {
              return value.level === 0 ? (parseFloat(`${value.product?.com_network}`) || 0) + sum : sum;
            }, 0) || 0,
            totalCommission: res.data.reduce((sum, value) => {
              return value.type !== 'PayOut' ? value.amount + sum : sum;
            }, 0),
            totalCommissionPaid: res.data.reduce((sum, value) => {
              return value.type === 'PayOut' && value.status === 'Confirmé' ? value.amount + sum : sum;
            }, 0),
            totalCommissionPayoutRequested: res.data.reduce((sum, value) => {
              return value.type === 'PayOut' && value.status !== 'Confirmé' ? value.amount + sum : sum;
            }, 0),
            numContrats: res.data.filter(e => e.type !== 'PayOut' && e.level === 0).length
          });
        })
        .catch((err) => {
          console.log("err", err);
          this.props.showMessage("Erreur inattendu");
        });
    })
  };

  onClose = () => {
    this.setState({ popupOpen: false });
  };

  openPartenaire = (partenaire) => {
    this.setState({ selectedPartenaire: partenaire, popupOpen: true });
  };

  addPartenaire = () => this.openPartenaire({});

  updateFilter = (field, value) => {
    let filter = this.state.filter;
    filter[field] = value;
    this.setState({ filter: Object.assign({}, filter) });
  }

  getBounds = (preDate) => {
    const lowerDate = new Date();
    if (preDate) {
      lowerDate.setDate(new Date(preDate).getDate() + 1);
    }

    const lowerBound = lowerDate.toISOString().split("T")[0];
    const date = new Date();

    date.setDate(lowerDate.getDate() + 3 * 365);

    const upperBound = date.toISOString().split("T")[0];
    return [lowerBound, upperBound];
  };

  render() {
    console.log('this.state.appComptables', this.state.appComptables);
    return (
      <Box style={{ width: "100%" }} margin={{ top: "medium" }}
        align="center" justify="center"
        alignSelf="center"
        alignContent="center" gap="medium">
        <Text size="xlarge" style={{ padding: 10 }}><b>Comptabilité</b></Text>
        <Box width="xlarge" gap="medium">
          <Box direction='row' justify="center" alignSelf="center" align="center" gap="medium">
            <Refresh refresh={this.loadAppComptables} />
            <Box direction='row' gap='small'
              alignSelf="center" >
              <Box pad="xsmall" justify="center" alignContent="center"
                gap="xsmall"
                align="center" direction="row">
                <Text size="xsmall">De</Text>
                <TextInput
                  size={"small"}
                  placeholder="Date de début"
                  disabled={true}
                  value={
                    this.state.startDate
                      ? new Date(this.state.startDate).toLocaleDateString("fr-FR")
                      : ""
                  }
                  style={{
                    opacity: 1,
                    background: "#EDEDED",
                    fontSize: 12,
                    borderRadius: 10,
                    border: "none",
                  }}
                />
                <DateInput
                  value={this.state.startDate.toISOString()}
                  calendarProps={{
                    locale: "fr-FR",
                    size: "small",
                    // bounds: this.getBounds(),
                  }}
                  onChange={(event) => {
                    this.setState({
                      startDate: new Date(event.value)
                    }, () => {
                      this.loadAppComptables();
                    });

                  }}
                />
                <Text size="xsmall">À</Text>
                <TextInput
                  size={"small"}
                  placeholder="Date de fin"
                  disabled={true}
                  value={
                    this.state.endDate
                      ? new Date(this.state.endDate).toLocaleDateString("fr-FR")
                      : ""
                  }
                  style={{
                    opacity: 1,
                    background: "#EDEDED",
                    fontSize: 12,
                    borderRadius: 10,
                    border: "none",
                  }}
                />
                <DateInput
                  value={this.state.endDate.toISOString()}
                  calendarProps={{
                    locale: "fr-FR",
                    size: "small",
                    // bounds: this.getBounds(),
                  }}
                  onChange={(event) => {
                    this.setState({
                      endDate: new Date(event.value)
                    }, () => {
                      this.loadAppComptables();
                    });

                  }}
                />
              </Box>
            </Box>
            <SearchComponent
              search={value => {
                this.setState({
                  query: value
                });
                console.log("query = ", value);
                this.loadAppComptables(this.state.startDate, this.state.endDate, value);
              }}
              query={this.state.query}
              updateQuery={value => this.setState({ query: value })}
            />
            <DateSort
              onChange={value => this.updateFilter('sort', value)}
            />
          </Box>

          <Box direction="row" align="center" justify="center" alignSelf="center" alignContent="center">
            {this.props.isMother && <Card pad='small' margin="small" align="center" justify="center" alignSelf="center" alignContent="center">
              <Text size="small">Total commission réseau mère</Text>
              <Text weight={'bold'}>{`${this.state.totalCommissionMother.toFixed(2)} €`}</Text>
            </Card>}
            {(this.props.isMother || this.props.isAdmin) && <Card pad='small' margin="small" align="center" justify="center" alignSelf="center" alignContent="center">
              <Text size="small">Total commission réseau</Text>
              <Text weight={'bold'}>{`${this.state.totalCommissionNetwork.toFixed(2)} €`}</Text>
            </Card>}
            {!this.props.hideCommission && <Card pad='small' margin="small" align="center" justify="center" alignSelf="center" alignContent="center">
              <Text size="small">Total commission</Text>
              <Text weight={'bold'}>{`${this.state.totalCommission.toFixed(2)} €`}</Text>
            </Card>}
            {!this.props.isMother && <Card pad='small' margin="small" align="center" justify="center" alignSelf="center" alignContent="center">
              <Text size="small">Total commission demandé</Text>
              <Text weight={'bold'}>{`${this.state.totalCommissionPayoutRequested.toFixed(2)} €`}</Text>
            </Card>}
            {!this.props.isMother && <Card pad='small' margin="small" align="center" justify="center" alignSelf="center" alignContent="center">
              <Text size="small">Total commission payé</Text>
              <Text weight={'bold'}>{`${this.state.totalCommissionPaid.toFixed(2)} €`}</Text>
            </Card>}
            <Card pad='small' margin="small" align="center" justify="center" alignSelf="center" alignContent="center">
              <Text size="small">Nb contrat</Text>
              <Text weight={'bold'}>{`${this.state.numContrats}`}</Text>
            </Card>
          </Box>

          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>
                  <Text>Création/<br />Modification</Text>
                </TableCell>
                <TableCell>
                  <Text>Client</Text>
                </TableCell>
                {(this.props.isMother) && <TableCell><Text>Apporteur</Text></TableCell>}
                {(this.props.isAdmin || this.props.isMother) && <TableCell><Text>Utilisateur</Text></TableCell>}
                <TableCell>
                  <Text>Produit</Text>
                </TableCell>
                <TableCell><Text>Niveau</Text></TableCell>
                {(!this.props.hideCommission) && <TableCell>
                  <Text color={'green'} weight='bold'>Commission apporteur</Text>
                </TableCell>}
                {(this.props.isAdmin || this.props.isMother) && <TableCell>
                  <Text color={'green'} weight='bold'>Commission réseau</Text>
                </TableCell>}
                {(this.props.isMother) && <TableCell>
                  <Text color={'green'} weight='bold'>Commission réseau mère</Text>
                </TableCell>}
              </TableRow>
            </TableHeader>
            <TableBody>
              {getSorted(this.state.appComptables, this.state.filter.sort).map((appComptable, index) => (
                <TableRow key={index + 1}>
                  <TableCell>
                    <Text size="small"><strong>{getSimpleDateFR(appComptable.createdAt)}</strong><br />{getSimpleDateFR(appComptable.updatedAt)}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{getClientName(appComptable.client)}</Text>
                  </TableCell>
                  {(this.props.isMother) && <TableCell>
                    <Text>{appComptable.user?.apporteur_pro?.raisonSocial}</Text>
                  </TableCell>}
                  {(this.props.isAdmin || this.props.isMother) && <TableCell>
                    <Text>{appComptable.user?.prenom} {appComptable.user?.nom}</Text>
                  </TableCell>}
                  <TableCell>
                    <Box>
                      <Text><b>{appComptable.product?.product}</b></Text>
                      <Text>{appComptable.product?.sub_product}</Text>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Text>{appComptable.level}</Text>
                  </TableCell>
                  {(!this.props.hideCommission) && <TableCell>
                    <Text color={'green'} weight='bold'>{appComptable.amount}€</Text>
                  </TableCell>}
                  {(this.props.isAdmin || this.props.isMother) && <TableCell>
                    {appComptable.level === 0 && <Text color={'green'} weight='bold'>{appComptable.product?.com_network}€</Text>}
                  </TableCell>}
                  {(this.props.isMother) && <TableCell>
                    {appComptable.level === 0 && <Text color={'green'} weight='bold'>{appComptable.product?.com_network_mother}€</Text>}
                  </TableCell>}
                  {/*                   
                  <TableCell>
                    <Button
                      label="voir"
                      primary
                      onClick={() => this.openPartenaire(appComptable)}
                    />
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    );
  }
}



export default AppComptable;
