import { Box, Button } from 'grommet';
import { Update } from 'grommet-icons';
import React from 'react';

const Refresh = ({ refresh }) => (
    <Box direction='row' gap='small' alignSelf="center"  width="100px">
        <Box round="full" overflow="hidden" pad="xsmall">
            <Button primary icon={<Update size='18px' />} onClick={refresh} />
        </Box>
    </Box>
)
export default Refresh;