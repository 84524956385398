import { Box } from 'grommet';
import { Search } from 'grommet-icons';
import React from 'react';
import CustomInput from '../custom_input';

const SearchComponent = ({ search, query, updateQuery, width, ...rest }) => {

    const [id, setId] = React.useState('');

    const localSearch = (_q) => {
        if (id) {
            clearTimeout(id);
        }
        setId(setTimeout(() => {
            search(_q);
        }, 500));
    }

    return (
        <Box direction='row' justify='center' align='center' style={{ background: '#EDEDED', boxShadow: 'none' }}
            round={{ size: 'xsmall' }}
        >
            <CustomInput
                placeholder="Search"
                value={query || ''}
                {...rest}
                style={{ shadow: 'none' }}
                onKeyPress={event => {
                    if (event.key === 'Enter') {
                        search(query);
                    }
                }}
                onChange={event => {
                    updateQuery(event.target.value);
                }}
            />

            <Box pad={'xsmall'} onClick={() => search(query)} 
                style={{ shadow: 'none' }}>
                <Search />
            </Box>
        </Box>
    )
}
export default SearchComponent;