const moment = require('moment');
const getSimpleDateFR = date => {
    if (!date) return date;
    if (typeof date == 'string' && date.includes('/')) {
        return date;
    }
    return moment(date).format('DD/MM/YYYY');
}
const getSimpleDateTimeFR = date => {
    if (!date) return date;
    if (typeof date == 'string' && date.includes('/')) {
        return date;
    }
    return moment(date).format('DD/MM/YYYY HH:mm');
}


const toCurrency = val => new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
}).format(val);


const getMonthOptions = () => {
    let options = [];
    options.push({ startDate : moment().subtract(24, "month").startOf("month").toDate(), endDate: moment().endOf("month").toDate(), name: "Tous les mois" });
    for (let i = 0; i < 12; i++) {
      let startDate = moment().subtract(i, "month").startOf("month").toDate();
      let endDate = moment().subtract(i, "month").endOf("month").toDate();
      let name = moment().subtract(i, "month").format("MM/YYYY");
      if (i === 0) name = "Mois en cours";
      options.push({ startDate, endDate, name });
    }
    let startDate = moment().subtract(11, "month").startOf("month").toDate();
    let endDate = new Date();
    let name = "12 derniers mois";
    options.push({ startDate, endDate, name });
    return options;
  };

module.exports = {
    getSimpleDateFR,
    getSimpleDateTimeFR,
    toCurrency,
    getMonthOptions
}