const axios = require('axios');
const storageService = require('../../../services/storage_service');
const { baseAPI } = require('../../../config/config.json');


const getAppConfig = () => {
    return axios.get(`${baseAPI}/app_configs?_limit=1`, {
        headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const getDashboardStats = () => {
    return axios.get(`${baseAPI}/app/dashboard_stats`, {
        headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const saveAppConfig = (appConfig) => {
    return axios.put(`${baseAPI}/app_configs/${appConfig.id}`, appConfig, {
        headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}



const getAppUsersApporteurs = (params) => {
    if (!params) {
        params = {};
    } else {
        params = { s: params };
    }
    console.log('params', params);

    return axios.get(`${baseAPI}/app/partenaire/apporteur/users?_limit=-1`, {
        params, headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const getAppUsers = (params) => {
    if (!params) {
        params = {};
    } else {
        params = { _query: params };
    }
    console.log('params', params);

    return axios.get(`${baseAPI}/app/users?_limit=-1`, {
        params, headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const getAppUsersValidated = (_params) => {
    var params = "";
    if (_params) {
        params = `&_where[_or][0][prenom_contains]=${_params}&_where[_or][1][nom_contains]=${_params}&_where[_or][2][email_contains]=${_params}`;
    }
    console.log('params', params);

    return axios.get(`${baseAPI}/app/users?_limit=-1&app_validated=1&email_contains=${storageService.getDomainAgence()}${params}`, {
        headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const getAppUsersNotValidated = (params) => {
    if (!params) {
        params = {};
    } else {
        params = { _query: params };
    }
    console.log('params', params);
    return axios.get(`${baseAPI}/app/users?_limit=-1&app_validated=0`, {
        params, headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const getAppUsersTeams = (params) => {
    if (!params) {
        params = {};
    } else {
        params = { _query: params };
    }
    console.log('params', params);
    return axios.get(`${baseAPI}/app/teams`, {
        params, headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const getRemunerations = (_startDate, _endDate, query) => {
    let params = {
        createdAt_gte: _startDate,
        createdAt_lte: _endDate
    };
    if (query) {
        params["_q"] = query;
    }
    return axios.get(`${baseAPI}/remunerations?_where[_or][0][apporteur.email_contains]=${storageService.getDomainAgence()}&_where[_or][1][user.email_contains]=${storageService.getDomainAgence()}&_limit=-1`, {
        params, headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const getRequestPayOut = (_startDate, _endDate, query) => {
    let params = {
        createdAt_gte: _startDate,
        createdAt_lte: _endDate
    };
    var _query = "";
    if (query) {
        _query = `&_where[_or][0][user.prenom_contains]=${query}&_where[_or][1][user.nom_contains]=${query}&_where[_or][2][amount_contains]=${query}`;
    }
    return axios.get(`${baseAPI}/remunerations?_where[user.email_contains]=${storageService.getDomainAgence()}&type=PayOut&_limit=-1${_query}`, {
        params, headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const putRequestPayOut = (id, params) => {
    return axios.put(`${baseAPI}/remunerations/${id}`, params, {
        headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}


const getAffilieByUser = (userid, params) => {
    if (!params) {
        params = {};
    } else {
        params = { _q: params };
    }
    return axios.get(`${baseAPI}/affilies-invitations?invited_by=${userid}&_sort=createdAt:desc`,
        params, {
        headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const getAffilieByApporteur = (apporteurId, params) => {
    if (!params) {
        params = {};
    } else {
        params = { _q: params };
    }
    return axios.get(`${baseAPI}/affilies-invitations?invited_by=${apporteurId}&_sort=createdAt:desc`,
        params, {
        headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const createAffilieInvitation = (params) => {
    return axios.post(`${baseAPI}/affilies-invitations`, params, {
        headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const saveAppUser = (params) => {
    console.log('params', params);
    return axios.put(`${baseAPI}/app/users`, params, {
        headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const getAbonnementByUser = (userid, params) => {
    if (!params) {
        params = {};
    } else {
        params = { _query: params };
    }
    return axios.get(`${baseAPI}/prepayments?objectId=${userid}&objectType=app_abonnement&_sort=createdAt:desc`, {
        params, headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const getRemunerationByUser = (userid, params) => {
    if (!params) {
        params = {};
    } else {
        params = { _query: params };
    }
    return axios.get(`${baseAPI}/remunerations?_limit=-1&user=${userid}&type=PayIn&_sort=createdAt:desc`, {
        params, headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const getLeadImmobiliers = (_startDate, _endDate, query) => {
    let params = {
        createdAt_gte: _startDate,
        createdAt_lte: _endDate
    };
    if (query) {
        params["_q"] = query;
    }
    return axios.get(`${baseAPI}/mise-en-relations?_where[apporteur.email_contains]=${storageService.getDomainAgence()}&_where[_or][0][produit]=Lead Mandat Immobilier&_where[_or][1][produit]=Recommandation Immo&_sort=createdAt:desc`, {
        params, headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const getMisEnRelation = (params) => {
    console.log('params', params);
    return axios.get(`${baseAPI}/mise-en-relations?_where[apporteur.email_contains]=${storageService.getDomainAgence()}&_sort=createdAt:desc`, {
        params, headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const getMisEnRelationApporteurs = (params) => {

    console.log('params', params);

    return axios.get(`${baseAPI}/app/partenaire/apporteur/mise_en_relation?_limit=-1`, {
        params, headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const getRemunerationApporteurs = (_type, _startDate, _endDate, query) => {
    let params = {
        type: _type,
        createdAt_gte: _startDate,
        createdAt_lte: _endDate
    };
    if (query) {
        params["s"] = query;
    }

    console.log('params', params);

    return axios.get(`${baseAPI}/app/partenaire/apporteur/remuneration?_limit=-1`, {
        params, headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const getRemunerationMaxTotal = () => {
    return axios.get(`${baseAPI}/app/partenaire/apporteur/max_total_remuneration`, {
        params: {}, headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const sendRequestRemunerations = (amount) => {
    return axios.post(`${baseAPI}/remunerations/request`, { amount }, {
        headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}



const updateApporteurTransaction = (devis, apporteur) => {
    return axios.post(`${baseAPI}/apporteur-change`, { devis, apporteur }, {
        headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const resendEmailInscription = (id) => {
    return axios.post(`${baseAPI}/apporteur-pros/resend_inscription`, { id }, {
        headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}
const resetPasswordPartenaire = (id) => {
    return axios.post(`${baseAPI}/apporteur-pros/reset_password`, { id }, {
        headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

const getSorted = (data, sort) => {
    if (!sort || !(data instanceof Array)) return data;
    console.log('data ', data);
    return (data || []).sort((d1, d2) =>
        sort === "DESC"
            ? d1.createdAt > d2.createdAt
                ? -1
                : 1
            : d1.createdAt > d2.createdAt
                ? 1
                : -1
    );
};

const getClientName = client => {
    return client?.raisonSocial ? client?.raisonSocial : `${client?.lastname} ${client?.firstname}`;
}

const createApporteurUsers = (params) => {
    return axios.post(`${baseAPI}/public/apporteurs/create_users`, params, {
        headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    });
}

module.exports = {
    getAppConfig,
    saveAppConfig,
    getDashboardStats,
    getAppUsers,
    getRemunerations,
    getAppUsersNotValidated,
    getAppUsersValidated,
    getAppUsersTeams,
    getAffilieByUser,
    saveAppUser,
    getAbonnementByUser,
    getRemunerationByUser,
    getRequestPayOut,
    getLeadImmobiliers,
    putRequestPayOut,
    createAffilieInvitation,
    updateApporteurTransaction,
    resendEmailInscription,
    resetPasswordPartenaire,
    getSorted,
    getMisEnRelation,
    getClientName,
    getAffilieByApporteur,
    getAppUsersApporteurs,
    createApporteurUsers,
    getMisEnRelationApporteurs,
    getRemunerationApporteurs,
    getRemunerationMaxTotal,
    sendRequestRemunerations
}